import axios from 'axios';
import { dropCurrentUser } from '../actions/currentUser.actions';
/*import { dropCurrentLoginAccount } from "../actions/accounts.actions";*/
import store from '../store';
import history from '../appHistory';
import localize from '../localization';
import { isArray } from 'lodash';
import { keycloakAuthInstance } from '../services/KeycloakAuth';

export const returnTariffCurrencySymbol = currency => {
  switch (currency) {
    case 'RUB':
      return '₽';
    case 'USD':
      return '$';
    default:
      return '€';
  }
};

export const getUserLanguage = () => {
  if (Boolean(localStorage.getItem('USER_LANGUAGE'))) {
    return localStorage.getItem('USER_LANGUAGE');
  } else {
    let lang = window.navigator.language.split('-')[0];

    switch (lang) {
      case 'ru': {
        return 'ru';
      }
      /*case 'cn':
            case 'zh': {
                return 'cn'
            }*/
      default:
        return 'eng';
    }
  }
};

export function getCurrentUserFromLocalStorage() {
  let localStorageCurUser = null;
  try {
    localStorageCurUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
  } catch (e) {
    localStorageCurUser = null;
  }

  return localStorageCurUser;
}

let axiosWithSessionCheck = axios.create({
  headers: {
    language: getUserLanguage().substr(0, 2).toUpperCase(),
    Product: 'license server',
  },
  validateStatus: status => {
    if (status === 401) {
      store.dispatch(dropCurrentUser());

      if (keycloakAuthInstance.isActive) {
        keycloakAuthInstance.login();
        return false;
      }

      history.push({
        pathname: '/login',
        state: { from: window.location.href.replace(window.location.origin, '') },
      });
      return false;
    } else if (status === 403) {
      history.push({ pathname: '/pages/403' });
      return false;
    }
    return status >= 200 && status < 300;
  },
});

export const CancelToken = axios.CancelToken;

export { axiosWithSessionCheck as axios };

export function timeoutPromise(timeout, err, promise) {
  return new Promise((resolve, reject) => {
    promise.then(resolve, reject);
    setTimeout(reject.bind(null, err), timeout);
  });
}

export const getUserValidName = (firstName, lastName, email) => {
  let computedName = '';
  if (!!firstName) {
    computedName += firstName + ' ';
  }
  if (!!lastName) {
    computedName += lastName;
  }
  if (computedName === '' && !!email) {
    computedName += email;
  }
  if (computedName === '') {
    computedName += localize.translate('No data specified');
  }
  return computedName;
};

export function startAnimation(callback) {
  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;
  const requestAnimationFrame = window.requestAnimationFrame;

  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      callback();
    });
  });
}

export const isAdvancedUpload = (function () {
  const div = document.createElement('div');
  return (
    ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
  );
})();

export const parseErrors = response => {
  let errors;
  //todo check response status, don't save error with 401 status
  if (response && response.status !== 401) {
    if (Boolean(response.data.errors)) {
      errors = response.data.errors;
    } else if (Boolean(response.data.error)) {
      errors = [response.data.error];
    } else if (isArray(response.data)) {
      errors = response.data;
    } else {
      errors = [response.data];
    }
  } else {
    errors = [];
  }

  return errors;
};

export const detectSelection = () => {
  let txt = '';
  if (window.getSelection) {
    txt = window.getSelection().toString();
  } else {
    // IE, используем объект selection
    txt = document.selection.createRange().text;
  }
  return txt;
};

export function flattenObject(ob) {
  let toReturn = {};

  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === 'object') {
      let flatObject = flattenObject(ob[i]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export function GA(event, eventCategory, eventAction, eventLabel = undefined, eventValue = undefined) {
  if (Boolean(window.dataLayer) && process.env.NODE_ENV !== 'development') {
    window.dataLayer.push({
      event: event,
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue,
    });
  }
}

export const getClosest = (elem, selector) => {
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

export const generateFormData = obj => {
  const formData = new FormData();

  Object.keys(obj).forEach(key => {
    formData.append(key, obj[key]);
  });

  return formData;
};

export function pluralize(count) {
  const pluralRules = new Intl.PluralRules(localize.getLocale());
  const grammaticalNumber = pluralRules.select(count);
  return grammaticalNumber;
}

/**
 * Converts ArrayBuffer to object.
 * @param {ArrayBuffer} buffer - ArrayBuffer to be converted.
 * @returns {Object} - Parsed object from ArrayBuffer.
 */
export function arrayBufferToObject(buffer) {
  try {
    if (!buffer) return;
    if ('TextDecoder' in window) {
      const dataView = new DataView(buffer);
      const decoder = new TextDecoder('utf8');
      return JSON.parse(decoder.decode(dataView));
    } else {
      const decodedString = String.fromCharCode.apply(null, new Uint8Array(buffer));
      return JSON.parse(decodedString);
    }
  } catch (error) {
    console.error(error);
  }
}
