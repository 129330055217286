import Keycloak, { KeycloakConfig, KeycloakInitOptions, KeycloakOnLoad, KeycloakPkceMethod } from 'keycloak-js';
import { AxiosInstance } from 'axios';

type KeycloakAppConfig = KeycloakConfig & {
  onLoad?: KeycloakOnLoad;
  pkceMethod?: KeycloakPkceMethod;
  checkLoginIframe?: boolean;
};

export class KeycloakAuth {
  isActive = false;
  isHeaderSat = false;

  private keycloakInstance: Keycloak | null = null;
  private initOptions: KeycloakInitOptions | null = null;
  private config: KeycloakAppConfig | null = null;

  public async init(config: KeycloakAppConfig, origin: string, redirectUrl: string): Promise<void> {
    this.config = config;
    this.isHeaderSat = false;

    this.keycloakInstance = new Keycloak({
      url: this.config.url,
      realm: this.config.realm || '',
      clientId: this.config.clientId || '',
    });

    this.initOptions = {
      onLoad: this.config?.onLoad,
      silentCheckSsoRedirectUri: `${origin}/silent-check-sso.html`,
      pkceMethod: this.config?.pkceMethod,
      checkLoginIframe: this.config?.checkLoginIframe,
      redirectUri: redirectUrl,
    };

    const isInitiated = await this.keycloakInstance.init(this.initOptions).catch(() => {});
    if (!isInitiated) throw new Error('Keycloak not initialized');
  }

  public async login() {
    if (!this.keycloakInstance) throw new Error('Keycloak not initialized');
    if (this.keycloakInstance.authenticated) return;
    return this.keycloakInstance.login({ redirectUri: this.initOptions?.redirectUri });
  }

  public getUser() {
    if (!this.keycloakInstance || !this.keycloakInstance.authenticated) return;
    return {
      firstName: this.keycloakInstance.tokenParsed?.given_name || '',
      lastName: this.keycloakInstance.tokenParsed?.family_name || '',
      email: this.keycloakInstance.tokenParsed?.email || '',
    };
  }

  addAuthHeaderToAxios(axios: AxiosInstance) {
    if (!this.keycloakInstance) return;
    this.isHeaderSat = true;
    axios.interceptors.request.use(config => {
      if (!this.keycloakInstance) return config;
      return this.keycloakInstance.updateToken(5).then(() => {
        config.headers = {
          ...config.headers,
          Authorization: 'Bearer ' + this.keycloakInstance?.token,
        };
        return config;
      });
    });
  }
}

export const keycloakAuthInstance = new KeycloakAuth();
