import {
  LOGIN,
  LOGOUT,
  SET_CURRENT_USER,
  DROP_CURRENT_USER,
  CHANGE_LANGUAGE,
  SET_LANGUAGE,
  GET_INSTANCE_LANGUAGE,
} from '../../constants/currentUser.actions';
import { axios } from '../../pipes/functions';

export const Base64 = {
  // private property
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  // public method for encoding
  encode: function (input) {
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    } // Whend

    return output;
  }, // End Function encode

  // public method for decoding
  decode: function (input) {
    let output = '';
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;

    input = input.replace(/[^A-Za-z0-9\+\/]/g, '');
    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output += String.fromCharCode(chr1);

      if (enc3 !== 64) {
        output += String.fromCharCode(chr2);
      }

      if (enc4 !== 64) {
        output += String.fromCharCode(chr3);
      }
    } // Whend

    output = Base64._utf8_decode(output);

    return output;
  }, // End Function decode

  // private method for UTF-8 encoding
  _utf8_encode: function (string) {
    let utftext = '';
    string = string.replace(/\r\n/g, '\n');

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    } // Next n

    return utftext;
  }, // End Function _utf8_encode

  // private method for UTF-8 decoding
  _utf8_decode: function (utftext) {
    let string = '';
    let i = 0;
    let c = 0,
      c2 = 0,
      c3 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    } // Whend

    return string;
  }, // End Function _utf8_decode
};

export const login = obj => {
  if (Boolean(obj)) {
    return {
      type: LOGIN,
      payload: axios.get('/authenticate', {
        headers: {
          Authorization: 'Basic ' + Base64.encode(obj.username + ':' + obj.password),
          'X-Requested-With': 'XMLHttpRequest',
        },
      }),
    };
  }
  return {
    type: LOGIN,
    payload: axios.get('/authenticate'),
  };
};

/*export const login = (dataObj) => {
    const formData = generateFormData(dataObj);

    return {
        type: LOGIN,
        payload: axios.post('/login', formData, {
            headers: {"Content-Type": "multipart/form-data;"}
        }),
    }
};*/

export const logout = () => {
  return {
    type: LOGOUT,
    payload: axios.get('/logout'),
  };
};

export const setUserLang = language => {
  return {
    type: SET_LANGUAGE,
    language,
  };
};

export const changeUserLang = (userId, language) => {
  return {
    type: CHANGE_LANGUAGE,
    payload: axios.put(
      `/restapi/users/${userId}/language`,
      {
        language,
      },
      {
        externalParams: language,
      }
    ),
  };
};

export const setCurrentUser = currentUser => {
  return {
    type: SET_CURRENT_USER,
    currentUser: currentUser,
  };
};

export const dropCurrentUser = () => {
  return {
    type: DROP_CURRENT_USER,
  };
};

export const getInstanceLanguage = () => {
  return {
    type: GET_INSTANCE_LANGUAGE,
    payload: axios.get('/public/options'),
  };
};
